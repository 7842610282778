<template>
  <v-row>
    <v-col class="mb-5" cols="auto" md="12" sm="12">
      <v-card>
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.drivingsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="vehicleId ? drivingsBasicHeaders : drivingsHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="drivings"
          :search="searchDrivingString"
          class="elevation-1 cursor-pointer"
          :items-per-page="20"
          @click:row="editDrivingItem"
          multi-sort>
          <template v-slot:item.driving_number="{ item }" class="d-flex align-center">
            <div class="d-flex align-center">
              {{ user?.role_id === 11 ? item.public_number : item.driving_number }}
              <v-img
                v-if="item.gnetData"
                alt="GNET"
                class="shrink ml-1"
                contain
                src="@/assets/gnet-small.png"
                transition="scale-transition"
                height="26px"
                width="28px" />
              <v-img
                v-if="item?.affiliate?.affiliate_data_record"
                alt="GNET"
                class="shrink ml-1"
                contain
                src="@/assets/limo-small.png"
                transition="scale-transition"
                height="26px"
                width="28px" />
              <v-icon v-if="item.paid" size="28px"> mdi-cash-check </v-icon>
            </div>
          </template>

          <template v-slot:item.drivingType.name="{ item }">
            {{ getTranslatedDrivingType(item.drivingType) }}
          </template>

          <template v-slot:item?.drivingProposal?.drivingProposalStatus="{ item }">
            {{ getStatus(item?.drivingProposal?.driving_proposal_status_id) }}
          </template>

          <template v-slot:item.vehicleClass.name="{ item }">
            {{ getVehicleClass(item?.vehicleClass?.name, item?.vehicle) }}
          </template>

          <template v-slot:item.user_id="{ item }">
            {{ getDriversName(item) }}
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <template v-if="!vehicleId && !clientId">
                <v-toolbar-title>
                  {{ $t('tables.drivings') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>
              </template>

              <v-text-field
                v-model="searchDrivingString"
                append-icon="mdi-magnify"
                hide-details
                :label="$t('tables.search')"
                single-line></v-text-field>

              <v-spacer />
              <template v-if="!vehicleId && user?.role_id !== 11">
                <v-dialog v-model="exportExcelDialog" max-width="700px" class="no-transition" :key="exportExcelDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('drivingsHeaders.exportExcel') }}
                    </v-btn>
                  </template>
                  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                    <v-form lazy-validation @submit.prevent="handleSubmit(exportToExcel)">
                      <v-card>
                        <v-card-title class="info title white--text font-weight-bold">
                          {{ $t('drivingsHeaders.exportExcel') }}
                          <v-spacer />
                          <v-icon class="mdi mdi-close" style="color: white" @click="closeExportDialog"></v-icon>
                        </v-card-title>

                        <v-card-text>
                          <v-container class="pa-0">
                            <v-row>
                              <v-col cols="12" lg="6" md="6" sm="6" class="mb-4">
                                <v-menu
                                  ref="fromDateFilter"
                                  v-model="fromDateFilter"
                                  :close-on-content-click="false"
                                  :return-value.sync="fromDate"
                                  min-width="auto"
                                  offset-y
                                  transition="scale-transition">
                                  <template v-slot:activator="{ on, attrs }">
                                    <validation-provider rules="required" v-slot="{ errors }" name="time_from">
                                      <v-text-field
                                        v-model="fromDate"
                                        clearable
                                        :label="$t('filters.fromDate')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </template>
                                  <DatePicker
                                    v-model="fromDate"
                                    :model-config="filterModelConfig"
                                    mode="date"
                                    :first-day-of-week="firstDay"
                                    @input="updateFromDateFilter(fromDate)"></DatePicker>
                                </v-menu>
                              </v-col>

                              <v-col cols="12" lg="6" md="6" sm="6" class="mb-4">
                                <v-menu
                                  ref="toDateFilter"
                                  v-model="toDateFilter"
                                  :close-on-content-click="false"
                                  :return-value.sync="toDate"
                                  min-width="auto"
                                  offset-y
                                  transition="scale-transition">
                                  <template v-slot:activator="{ on, attrs }">
                                    <validation-provider rules="required" v-slot="{ errors }" name="time_to">
                                      <v-text-field
                                        v-model="toDate"
                                        clearable
                                        :label="$t('filters.toDate')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </template>
                                  <DatePicker
                                    v-model="toDate"
                                    :model-config="filterModelConfig"
                                    mode="date"
                                    :first-day-of-week="firstDay"
                                    @input="updateToDateFilter(toDate)"></DatePicker>
                                </v-menu>
                              </v-col>

                              <v-col
                                cols="12"
                                lg="4"
                                md="4"
                                sm="6"
                                v-for="checkbox in labeledCheckboxes"
                                :key="checkbox.key"
                                class="py-0">
                                <v-checkbox
                                  v-model="checkboxData[checkbox.key]"
                                  :label="$t(checkbox.label)"
                                  class="mt-0"></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-checkbox
                            v-model="selectAllCheckboxes"
                            :label="$t('drivingExcelCheckboxes.selectAll')"
                            class="mt-0"></v-checkbox>

                          <v-spacer />
                          <!-- <v-btn color="primary" @click="exportToExcel">Export</v-btn> -->
                          <button-submit
                            :failed="failed"
                            :loading="loading"
                            buttonText="buttons.export"></button-submit>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </validation-observer>
                </v-dialog>
              </template>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn text v-on="{ ...on, ...tooltipOn }">
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('buttons.actions') }}
                  </span>
                </v-tooltip>
              </template>

              <v-list>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="editDrivingItem(item)">
                      <v-icon class="mx-1" small v-bind="attrs">mdi-eye</v-icon>
                      <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                        {{ $t('buttons.open') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ $t('tooltips.open') }}</span>
                </v-tooltip>

                <template v-if="[1, 2, 4].includes(user.role_id)">
                  <v-tooltip v-if="user?.organisation?.organisation_settings?.stripe_connect_enabled" left>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="display: block" v-bind="attrs" v-on="on">
                        <v-list-item :disabled="disableGeneratePaymentLink" @click="generatePaymentLink(item)">
                          <v-icon class="mx-1" small>mdi-cash-multiple</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.generatePaymentLink') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span v-if="user?.organisation?.organisation_settings?.stripe_connect_enabled">
                      {{ $t('tooltips.generatePaymentLink') }}
                    </span>
                    <span v-else>
                      {{ $t('tooltips.cannotGeneratePaymentLInk') }}
                    </span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="display: block" v-bind="attrs" v-on="on">
                        <v-list-item @click="generateDrivingOrder(item)">
                          <v-icon class="mx-1" small>mdi-car</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.generateDrivingOrder') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span>
                      {{ $t('tooltips.generateDrivingOrder') }}
                    </span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="display: block" v-bind="attrs" v-on="on">
                        <v-list-item :disabled="!item.client" @click="printInvoice(item)">
                          <v-icon class="mx-1" small>mdi-printer</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.printInvoice') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span v-if="item.client">{{ $t('tooltips.printInvoice') }}</span>
                    <span v-else>{{ $t('tooltips.cannotPrintInvoiceWithoutClient') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="display: block" v-bind="attrs" v-on="on">
                        <v-list-item :disabled="disableMarkAsDone(item)" @click="openFinishDrivingDialog(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-check-outline</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.markAsDone') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span v-if="!disableMarkAsDone(item)">
                      {{ $t('tooltips.markAsDone') }}
                    </span>
                    <span
                      v-else-if="
                        getStatusId(item) == 3 ||
                        (item?.affiliate?.status == 'rejected' &&
                          item.drivingProposal?.drivingProposalStatus == 'Rejected')
                      ">
                      {{ $t('tooltips.cannotMarkAsDoneStatusRejected') }}
                    </span>
                    <span v-else-if="getStatusId(item) == 5">
                      {{ $t('tooltips.cannotMarkAsDoneStatusCanceled') }}
                    </span>
                    <span v-else-if="getStatusId(item) == 7">
                      {{ $t('tooltips.cannotMarkAsDoneStatusDone') }}
                    </span>
                    <span v-else-if="getStatusId(item) == 12">
                      {{ $t('tooltips.cannotMarkAsDoneStatusNoShow') }}
                    </span>
                    <span v-else-if="new Date(item.pickup_time_original) > new Date()">
                      {{ $t('tooltips.cannotMarkAsDone') }}
                    </span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="display: block" v-bind="attrs" v-on="on">
                        <v-list-item @click="$emit('duplicate', item)" :disabled="disabledForAffiliate">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-content-copy</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.duplicate') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span v-if="disabledForAffiliate">
                      {{ $t('tooltips.disabledForAffiliateUser') }}
                    </span>
                    <span v-else>{{ $t('tooltips.duplicate') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="display: block" v-bind="attrs" v-on="on">
                        <v-list-item
                          :disabled="item.drivingType.id === 2 || disabledForAffiliate"
                          @click="$emit('makeReturnTrip', item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-repeat</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.makeReturnTrip') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span v-if="disabledForAffiliate">
                      {{ $t('tooltips.disabledForAffiliateUser') }}
                    </span>
                    <span v-else-if="item.drivingType.id === 1">{{ $t('tooltips.makeReturnTrip') }}</span>
                    <span v-else>{{ $t('tooltips.cannotMakeReturnTrip') }}</span>
                  </v-tooltip>
                </template>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-dialog v-model="finishDrivingDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 primary" dark>
          {{ $t('driving.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          <p>{{ $t('driving.EnterTheNumberOfHoursWaiting') }}</p>
          <validation-provider rules="numeric|min_value:0" v-slot="{ errors }" name="\">
            <v-text-field
              v-model="finishDrivingItem.num_of_waiting_hours"
              clearable
              :label="$t('driving.waitingTime')"
              type="text"
              :error-messages="errors"></v-text-field>
          </validation-provider>
          <p>{{ $t('driving.finishDriving') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary cancel" text @click="finishDrivingDialog = false">
            {{ $t('buttons.no') }}
          </v-btn>
          <v-btn class="primary" text @click="finishDriving" :disabled="loadingFinish" :loading="loadingFinish">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import i18n from '@/i18n/i18n';
import { drivingsBasicHeaders, drivingsHeaders } from '@/mixins/data-table-headers';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { DatePicker } from 'v-calendar/src/components';
import store from '@/store';
export default {
  name: 'DrivingsTable',
  props: ['drivings', 'drivers', 'vehicleId', 'clientId', 'filter_vehicle_id', 'filter_client_id', 'filter_driver_id'],
  components: { DatePicker, ButtonSubmit },
  data: () => ({
    searchDrivingString: '',
    exportExcelDialog: false,
    fromDateFilter: null,
    toDateFilter: null,
    fromDate: '',
    toDate: '',
    filterModelConfig: {
      type: 'string',
      mask: 'DD-MM-YYYY',
    },
    excelCheckboxesLabels: [
      { number: 'drivingExcelCheckboxes.drivingNumber' },
      { driving_type: 'drivingExcelCheckboxes.drivingType' },
      { driving_status: 'drivingExcelCheckboxes.drivingStatus' },
      { from_location: 'drivingExcelCheckboxes.locationFrom' },
      { to_location: 'drivingExcelCheckboxes.locationTo' },
      { drivers_name: 'drivingExcelCheckboxes.driver' },
      { client: 'drivingExcelCheckboxes.client' },
      { plate_number: 'drivingExcelCheckboxes.plateNumber' },
      { note: 'drivingExcelCheckboxes.note' },
      { fuel_consumption: 'drivingExcelCheckboxes.fuelConsumption' },
      { pickup_time: 'drivingExcelCheckboxes.pickupTime' },
      { number_of_passengers: 'drivingExcelCheckboxes.passengers' },
      { passengers: 'drivingExcelCheckboxes.passengers' },
      { price: 'drivingExcelCheckboxes.price' },
      { paid: 'drivingExcelCheckboxes.paid' },
      { payment_method: 'drivingExcelCheckboxes.paymentMethod' },
      { num_of_waiting_hours: 'drivingExcelCheckboxes.waitingTime' },
      {
        distance:
          store.getters['auth/user']?.organisation?.organisation_settings?.distance_unit == 'mi'
            ? 'drivingExcelCheckboxes.distanceMi'
            : 'drivingExcelCheckboxes.distance',
      },
    ],
    checkboxData: {
      client: true,
      distance: true,
      drivers_name: true,
      driving_status: true,
      driving_type: true,
      from_location: true,
      fuel_consumption: true,
      note: true,
      num_of_waiting_hours: true,
      number: true,
      number_of_passengers: true,
      paid: true,
      passengers: true,
      payment_method: true,
      pickup_time: true,
      plate_number: true,
      price: true,
      to_location: true,
    },
    selectAllCheckboxes: false,
    user: {},
    loading: false,
    finishDrivingDialog: false,
    finishDrivingItem: {},
    loadingFinish: false,
  }),
  created() {
    this.user = this.$store.getters['auth/user'];

    if (this.user.role_id !== 11) {
      if (this.user?.user_export_preferences?.driving_export_columns) {
        this.checkboxData = JSON.parse(this.user?.user_export_preferences?.driving_export_columns);
      } else {
        this.$store
          .dispatch('drivings/userExportPreferences')
          .then((res) => {
            if (res.data.driving_export_columns) {
              this.checkboxData = JSON.parse(res.data.driving_export_columns);
            }
          })
          .catch(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          });
      }
    }
  },
  computed: {
    drivingsHeaders() {
      return drivingsHeaders(i18n);
    },
    drivingsBasicHeaders() {
      return drivingsBasicHeaders(i18n);
    },

    labeledCheckboxes() {
      return this.excelCheckboxesLabels.map((labelObj) => {
        const key = Object.keys(labelObj)[0];
        return {
          key,
          label: labelObj[key],
        };
      });
    },
    disableGeneratePaymentLink() {
      return !this.user?.organisation?.organisation_settings?.stripe_connect_enabled;
    },
  },
  methods: {
    editDrivingItem(item) {
      this.$emit('show-event', item);
    },

    getStatus(id) {
      const drivingProposalStatus = {
        1: 'drivingStatus.pending',
        2: 'drivingStatus.accepted',
        3: 'drivingStatus.rejected',
        4: 'drivingStatus.expired',
        5: 'drivingStatus.canceled',
        6: 'drivingStatus.draft',
        7: 'drivingStatus.done',
        12: 'drivingStatus.noShow',
      };

      return drivingProposalStatus[id] ? i18n.t(drivingProposalStatus[id]) : '';
    },

    getStatusId(item) {
      const proposal = item?.drivingProposal;
      let id;
      if (item?.id && proposal) {
        id = parseInt(proposal.driving_proposal_status_id);
      }

      return id;
    },

    getVehicleClass(vehicleClass, vehicle) {
      return vehicleClass ? vehicleClass : vehicle?.vehicleClass?.name ? vehicle?.vehicleClass.name : '';
    },

    getTranslatedDrivingType(drivingType) {
      const drivingTypeMap = {
        1: 'transfer',
        2: 'dailyRent',
      };
      return drivingTypeMap[drivingType.id] ? i18n.t(`drivingOrderType.${drivingTypeMap[drivingType.id]}`) : '';
    },
    getDriversName(item) {
      if (this.user?.role_id == 11) {
        return item.drivingProposal.driver
          ? item?.drivingProposal?.driver?.first_name +
              ' ' +
              item?.drivingProposal?.driver?.last_name +
              ' (' +
              item?.drivingProposal?.driver?.phone +
              ')'
          : '';
      } else {
        let index = this.drivers.findIndex((x) => x.id == item?.drivingProposal?.user_id);
        return this.drivers[index]?.full_name;
      }
    },

    updateFromDateFilter(fromDate) {
      this.$refs.fromDateFilter.save(fromDate);
      this.selectedFromDate = fromDate;
    },
    updateToDateFilter(toDate) {
      this.$refs.toDateFilter.save(toDate);
      this.selectedToDate = toDate;
    },
    closeExportDialog() {
      this.exportExcelDialog = false;
      this.$nextTick(() => {
        this.fromDate = null;
        this.toDate = null;
      });
      this.$refs.form.reset();
    },

    exportToExcel() {
      if (this.fromDate && this.toDate) {
        const userOld = this.$store.getters['auth/user'];
        const user = {
          ...userOld,
          user_export_preferences: {
            ...userOld.user_export_preferences,
            driving_export_columns: JSON.stringify(this.checkboxData),
          },
        };
        this.$store.dispatch('auth/setUser', user);
        localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        this.loading = true;

        this.$store
          .dispatch('drivings/exportExcel', {
            from: this.fromDate,
            to: this.toDate,
            vehicle_id: this.filter_vehicle_id,
            driver_id: this.filter_driver_id,
            client_id: this.filter_client_id,
            columns: JSON.stringify(this.checkboxData),
          })
          .then((response) => {
            if (response.path) {
              window.open(response.path, '_blank');
              this.$store.dispatch('showSnackbar', {
                text: i18n.t('snackbar.excelDownloadedSuccessfully'),
                color: 'green',
              });
              this.closeExportDialog();
            } else {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
            } else {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    generatePaymentLink(item) {
      const data = {
        drivingId: item.id,
      };

      this.$store
        .dispatch('drivings/generateDrivingPaymentLink', data)
        .then((res) => {
          window.open(res.data, '_blank');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        });
    },
    generateDrivingOrder(item) {
      this.$store
        .dispatch('drivings/generateDrivingOrder', item.id)
        .then((res) => {
          let file = res.path;

          if (!res.path.includes('https://') && !res.path.includes('http://')) {
            file = process.env.VUE_APP_BACKEND_URL.concat(res.path.replace('public', 'storage'));
          }

          window.open(file, '_blank');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    printInvoice(item) {
      this.$store
        .dispatch('drivings/printInvoice', item.id)
        .then((res) => {
          let file = '';
          if (!res.path.includes('https://') && !res.path.includes('http://')) {
            file = process.env.VUE_APP_BACKEND_URL.concat(res.path.replace('public', 'storage'));
          } else {
            file = res.path;
          }

          window.open(file, '_blank');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    disableMarkAsDone(item) {
      const proposal = item?.drivingProposal;

      return (
        new Date(item.pickup_time_original) > new Date() ||
        proposal.drivingProposalStatus === 'Rejected' ||
        proposal.drivingProposalStatus === 'Canceled' ||
        proposal.drivingProposalStatus === 'Done' ||
        proposal.drivingProposalStatus === 'No Show' ||
        (item?.affiliate?.status == 'rejected' && item.drivingProposal?.drivingProposalStatus == 'Rejected')
      );
    },

    openFinishDrivingDialog(item) {
      this.finishDrivingItem = item;
      this.finishDrivingDialog = true;
    },

    async finishDriving() {
      const body = {
        id: this.finishDrivingItem.id,
        num_of_waiting_hours: parseInt(this.finishDrivingItem.num_of_waiting_hours.toString(), 10),
      };
      await this.$store
        .dispatch('drivings/finishDriving', body)
        .then((res) => {
          if (res.gnet_sync_success == false) {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.savedSuccessfullyWithGnetError'),
              color: 'green',
            });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.drivingFinished'), color: 'green' });
          }
          this.selectedOpen = false;
          this.$store.commit('updatedNewDrivingCounter');
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loadingFinish = false;
          this.finishDrivingDialog = false;
        });
    },
  },
  watch: {
    exportExcelDialog(val) {
      if (!val) {
        this.closeExportDialog();
      }
    },
    selectAllCheckboxes(val) {
      for (const key in this.checkboxData) {
        if (this.checkboxData.hasOwnProperty(key)) {
          this.checkboxData[key] = val;
        }
      }
    },
  },
};
</script>
